export type ImageArray = [
  { type: '160'; url: string },
  { type: '320'; url: string },
  { type: '640'; url: string },
  { type: '800'; url: string },
];

export type ProductImage = {
  conversions: ImageArray | null;
  is_main: boolean;
};

export const addImageLocalPathPrefix = (url: string): string => {
  if (!url) return '';
  const externalDomain = `${process.env.NEXT_PUBLIC_IMAGE_URL}/products`;
  const localPathPrefix = '/images/products';

  if (url?.startsWith(externalDomain)) {
    return url.replace(externalDomain, localPathPrefix);
  }

  return url;
};

export const replaceImageLocalPathPrefix = (url: string): string => {
  if (!url) return '';
  const externalDomain = `${process.env.NEXT_PUBLIC_IMAGE_URL}/products`;
  const localPathPrefix = '/images/products';

  if (url?.startsWith(localPathPrefix)) {
    return url.replace(localPathPrefix, externalDomain);
  }

  return url;
};

export const formatSrcSet = (images: ImageArray) => {
  return (
    images?.map((image) => `${addImageLocalPathPrefix(image.url)} ${image.type}w`).join(', ') || ''
  );
};
